<template>
  <div class="dynamic_item new_order_container">
		<div class="products_scroll_zone_container">
			<div class="products_scroll_zone">
				<!-- <div class="products_container">
					<p class="product_category_text_item">Категория 1</p>
          <component-product-item 
            v-for="item in productsArr"
            :price="item.price"
            :photo="item.photo"
            :name="item.name"
            :stopper="stopper"
            :findParentByClassName="findParentByClassName"
            v-bind:key="item.id"
            @setNewPrice="setNewAmount">
          </component-product-item>
					<p class="product_category_text_item">Категория 2</p>		
				</div> -->
        <component-products-creator
          :productsArr="productsArr"
          :findParentByClassName="findParentByClassName"
          :stopper="stopper"
          @setNewPrice="setNewAmount">
        </component-products-creator>
			</div>
		</div>
		<div class="new_order_amount_container">
			<div class="amount_control_container">
				<div class="new_order_price_container">
					<p class="new_order_price_title">К оплате:</p>
					<p class="new_order_price">
						<span class="price_text">{{fullPrice}}</span>
						<span class="price_symbol"></span>
					</p>
				</div>
        <router-link to="/orders" class="receipt_button"></router-link>
				<a href="#" id="pay_final_button" @click="startPay($event)" class="classic_button white_button"><span>Оплатить</span></a>
				<a href="#" @click="addEmail($event)" class="add_pay_email"><span class="ok_box"></span>Получить чек на e-mail</a>
			</div>
			<form action="" class="pay_email_form">
				<div class="back_button_wrapper">
					<a href="#" @click="backToPay($event)" class="back_button">Вернуться к оплате</a>
				</div>
				<input type="email" name="pay_email" class="pay_email" placeholder="Введите e-mail">
				<button type="submit" @click="payEmailCLick($event)" id="pay_email_button">ok</button>
				<p class="label_error">{{errorText}}</p>
			</form>
			<div class="pay_systems_container">
				<a :href="footerRules" v-if="footerRules" target="_blank" class="pay_sistems_info_link">Подробная информация о правилах оплаты, возврате денежных средств, реквизиты организации, инструкция как сделать заказ</a>
				<p class="pay_warning_info" v-if="footerText" v-html="footerText"></p>
				<!-- <p class="pay_warning_info">Текст подвала</p> -->
			</div>
		</div>
	</div>
</template>

<script>
import Product_Item from '@/components/Product_Item'
import Products_Creator from '@/components/Products_Creator'
import axios from 'axios'
import VueCookies from 'vue-cookies'

export default {
  name: 'Products',
  data() {
    return {
      useEmail: false,
      clientEmail: null,
      productsArr: [],
      fullPrice: 0,
      keyEIN: '',
      errorText: '',
    }
  },
  props: [
    'maxAmount', 
    'stopper', 
    'findParentByClassName', 
    'addGAEvent',
    'checkEmail',
    'showSomeBadPopup',
    'footerText',
    'footerRules'
  ],
  components: {
    'component-product-item': Product_Item,
    'component-products-creator': Products_Creator,
  },
  methods: {
    setNewAmount() {
      let productItems = document.querySelectorAll('.product_item')

      this.fullPrice = 0

      for (let i = 0; i < productItems.length; i++) {
        let productPrice = parseInt(productItems[i].dataset.price)
        let productAmount = parseInt(productItems[i].dataset.amount)
        let productCost = productPrice * productAmount

        this.fullPrice += productCost
      }

      let amountControlContainer = document.querySelector('.amount_control_container')
      amountControlContainer.classList.remove('empty')
    },
    backToPay($event) {
      this.stopper($event)

      let amountContainer = this.findParentByClassName('new_order_amount_container', $event.target)
      amountContainer.classList.remove('enter_pay_email')
    },
    addEmail($event) {
      this.stopper($event)

      this.errorText = ''
      let button = this.findParentByClassName('add_pay_email', $event.target)

      if (button.classList.contains('added_email')) {
        this.useEmail = false;
        button.classList.remove('added_email')
        return
      }

      let amountContainer = this.findParentByClassName('new_order_amount_container', button)
      amountContainer.classList.add('enter_pay_email')
    },
    updateValue(target, value) {
      this.$emit(target, value)
    },
    payEmailCLick($event) {
      this.stopper($event);

      let payEmailForm = document.querySelector('.pay_email_form');
      let payEmail = payEmailForm.querySelector('.pay_email');
      let emailValue = payEmail.value;

      if (!emailValue) {
        this.errorText = 'Введите email'
        return;
      }

      if (emailValue && !this.checkEmail(emailValue)) {
        this.errorText = 'Необходимо ввести корректный email'
        return
      }

      this.clientEmail = emailValue;
      this.useEmail = true;

      let amountContainer = this.findParentByClassName('new_order_amount_container', payEmailForm);
      let emailButton = amountContainer.querySelector('.add_pay_email');

      emailButton.classList.add('added_email');
      amountContainer.classList.remove('enter_pay_email');

      this.addGAEvent('Email', 'Email', 'Send', 'Success');
    },
    startPay(event) {
      this.stopper(event);

      let amountControlContainer = document.querySelector('.amount_control_container');
      amountControlContainer.classList.remove('empty');

      if (!this.fullPrice) {
        setTimeout(function() {
          amountControlContainer.classList.add('empty');
        }, 100);

        return;
      }

      let payEmail = '';

      if (this.useEmail && this.clientEmail) {
        payEmail = this.clientEmail;
      }

      let itemsJSON = this.createItemJson()

      let data = {
        email: payEmail,
        items: itemsJSON,
        total_price: this.fullPrice,
      }

      let headers = {
        'ein-x': this.keyEIN,
        'x-val-use': 'Z4M2goU2cvvUQUE4ZWh70Cu6XfrwY1',
      }

      axios.post('https://platillo.ru/api/v1/order/create', data, {
          headers: headers
        })
        .then((value) => {
          console.log('Success answer /order/create')
          console.dir(value);

          let url = value.data.data['link_request'];

          if (url) {
            window.location.href = url;
          }
        })
        .catch((response) => {
          console.log('Bad answer /order/create')
          console.dir(response);
          this.showSomeBadPopup();
        })
    },
    createItemJson() {
      let products = document.querySelectorAll('.not_empty');

      if (!products.length) {
        return;
      }

      let arrJSON = [];

      for (let i = 0; i < products.length; i++) {
        let obj = {};

        let name = products[i].dataset.name;
        let price = parseInt(products[i].dataset.price);
        let quantity = parseInt(products[i].dataset.amount);
        let sum = price * quantity;

        obj['Name'] = name;
        obj['Price'] = price*100;
        obj['Quantity'] = quantity;
        obj['Amount'] = sum*100;
        obj['Tax'] = 'vat20';

        arrJSON.push(obj)
      }

      // return JSON.stringify(arrJSON);
      return arrJSON;
    },
    feedPayForm(sum, orderId, userEmail, userCallback, cart, linkForm) {
      let payForm = document.querySelector('#payform');

      let sumInput = payForm.querySelector('input[name="sum"]');
      let orderIdInput = payForm.querySelector('input[name="orderid"]');
      let clientEmailInput = payForm.querySelector('input[name="client_email"]');
      let userCallbackInput = payForm.querySelector('input[name="user_result_callback"]');
      let cartInput = payForm.querySelector('input[name="cart"]');
      let paySumbit = payForm.querySelector('input[type="submit"]');

      payForm.setAttribute("action", linkForm);

      sumInput.value = sum;
      orderIdInput.value = orderId;
      clientEmailInput.value = userEmail;
      userCallbackInput.value = userCallback;
      cartInput.value = cart;

      paySumbit.click();
    },
	},
  mounted() {

  },
  created() {
    let cookieData = VueCookies.get("ein-x")

    let headers = {
      'x-val-use': 'Z4M2goU2cvvUQUE4ZWh70Cu6XfrwY1',
    }

    if (cookieData) {
      this.keyEIN = cookieData
      headers['ein-x'] = this.keyEIN
    }

    axios.get(
      'https://platillo.ru/api/v1/get/product',
      {headers: headers
    }).then((value) => {
        console.log('Success answer /get/product')
        console.dir(value)

        this.productsArr = value.data.data.products
        let einX = value.data.data['ein-x']

        if (einX) {
          this.keyEIN = value.data.data['ein-x']
        }
        
        if (this.keyEIN) {
          VueCookies.set("ein-x", this.keyEIN, 60 * 60 * 24 * 365)
        }
      }
    )
  },
  watch: {
    footerText(val) {
      if (val) {
        let payWarningInfo = document.querySelector('.pay_warning_info');

        payWarningInfo.innerHTML = val;
      }
    }
  }
}
</script>

<style scoped>
  .products_scroll_zone_container {
    display: block;
    width: 100%;
    height: 57.5rem;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    margin-top: 1rem;
  }
  .products_scroll_zone {
    display: block;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0 1.5vw 0 3vw;
    box-sizing: border-box;
    position: relative;
  }
  .products_scroll_zone:empty {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 15rem;
  }
  .products_scroll_zone:empty:before {
    content: '';
    display: block;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    border: 2px dashed var(--color-1);
    animation: empty_products 3s linear infinite;
  }
  .products_scroll_zone:empty:after {
    content: 'Загрузка';
    display: block;
    width: 100%;
    height: auto;
    margin-top: 2rem;
    font-size: 3rem;
    line-height: 1.1em;
    font-weight: 500;
    color: var(--color-1);
  }
  @keyframes  empty_products {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .products_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    padding-bottom: 1rem;
    padding-right: 1.5vw;
    box-sizing: border-box;
  }
  .new_order_amount_container {
    display: block;
    width: 100%;
    height: auto;
    padding: .5rem 0 14rem 3vw;
    box-sizing: border-box;
    transition: transform .4s ease;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
  .new_order_amount_container > * {
    position: relative;
    z-index: 2;
  }
  .new_order_amount_container:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: var(--color-1);
  }
  .amount_control_container {
    display: block;
    width: 100%;
    height: 18rem;
  }
  .enter_pay_email .amount_control_container {
    display: none;
  }
  .pay_email_form {
    display: none;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    height: 18rem;
    position: relative;
    z-index: 2;
    padding-right: 6vw;
    box-sizing: border-box;
    padding-top: 2rem;
  }
  .pay_email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 75%;
    height: 7rem;
    text-align: left;
    color: #000000;
    font-size: 2rem;
    line-height: 1em;
    padding-left: 1em;
    box-sizing: border-box;
    border: none;
    outline: none;
  }
  .pay_email::placeholder {
    color: #B3B3B3;
    font-family: 'Montserrat';
  }
  .enter_pay_email .pay_email_form {
    display: flex;
  }
  .pay_email_form .label_error {
    color: #FFFFFF;
  }
  .empty .new_order_price_container {
    animation: empty_sum 1s ease forwards;
  }
  #pay_email_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 7rem;
    font-size: 2.6rem;
    line-height: 1em;
    background-color: #000000;
    color: #FFFFFF;
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 900;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .pay_email_form .label_error {
    width: 100%;
  }
  .back_button_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: auto;
  }
  .back_button {
    display: block;
    width: auto;
    height: auto;
    text-align: center;
    font-size: 2rem;
    line-height: 1.1em;
    color: #FFFFFF;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 2px solid #FFFFFF;
    cursor: pointer;
    margin-bottom: 2rem;
  }
  
  @keyframes empty_sum {
    0% {
      transform: none;
    }
    25% {
      transform: translateX(-2rem);
    }
    75% {
      transform: translateX(2rem);
    }
    100% {
      transform: none;
    }
  }
  .new_order_price_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 30rem;
    height: auto;
  }
  .new_order_price_title {
    display: block;
    width: auto;
    height: auto;
    text-align: left;
    font-size: 1.8rem;
    line-height: 1em;
    font-weight: 500;
    color: #FFFFFF;
  }
  .new_order_price {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: auto;
    flex-wrap: nowrap;
  }
  .price_text {
    display: block;
    width: auto;
    height: auto;
    text-align: left;
    font-size: 4.5rem;
    line-height: 1em;
    font-weight: 700;
    color: #FFFFFF;
    border-bottom: 2px solid #FFFFFF;
  }
  .price_symbol {
    display: block;
    width: 2rem;
    height: 2.5rem;
    background-image: url(../assets/img/icon_rub.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-left: .5rem;
  }
  .receipt_button {
    color: #FFFFFF;
    position: absolute;
    right: 1rem;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: 7rem;
    width: 7rem;
    background: url(../assets/img/receipt.png) 50% 50% no-repeat;
    background-size: contain;
  } 
  #pay_final_button {
    margin-top: 1.2rem;
    font-size: 2.2rem;
  }
  .add_pay_email {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    text-align: left;
    font-size: 1.6rem;
    line-height: 1.1em;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
    font-weight: 400;
    margin-top: 2rem;
    padding-left: 2em;
    box-sizing: border-box;
    position: relative;
  }
  .ok_box {
    display: block;
    width: 1.4em;
    height: 1.4em;
    border: 1px solid #FFFFFF;
    border-radius: 1px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
  }
  .ok_box:before {
    content: '';
    display: block;
    width: 90%;
    height: 90%;
    background-image: url(../assets/img/icon_ok_white.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    opacity: 0;
  }
  .add_pay_email.added_email .ok_box:before {
    opacity: 1;
  }
  .pay_systems_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: #FFFFFF;
    padding: 1rem 0 1rem;
    position: fixed;
    left: 0;
    bottom: 0;
  }
  .pay_systems_container:before {
    content: '';
    display: block;
    width: 70%;
    height: 3.2rem;
    background-image: url(../assets/img/sprite_pay.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .pay_sistems_info_link {
    display: block;
    width: 88%;
    height: auto;
    text-align: center;
    font-size: 1.3rem;
    line-height: 1.1em;
    color: #168EF8;
    text-decoration: underline;
    margin-top: 1rem;
  }
  .pay_warning_info {
    display: block;
    width: 98%;
    height: auto;
    text-align: center;
    font-size: 1.3rem;
    line-height: 1.1em;
    margin-top: .5rem;
    color: #787878;
  }

  /* desktop */

  @media screen and (min-aspect-ratio: 4/3) {
    .new_order_container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    .new_order_amount_container {
      position: fixed;
      left: 0;
      bottom: 0;
      padding: 1rem 0 14rem 3vw;
    }
  }

  /* iPhone 4/4S */

  @media 
    only screen and (min-device-width: 320px) 
    and (max-device-width: 480px) 
    and (-webkit-device-pixel-ratio: 2) 
    and (device-aspect-ratio: 2/3) {
      
    .new_order_container {
    	height: 65rem;
    }
    .new_order_price_title {
        font-size: 2rem;
    }
    .new_order_price_container {
      width: 35rem;
    }
    .price_text {
      font-size: 6rem;
    }
    .amount_control_button {
      font-size: 3.6rem;
    }
    .new_order_amount_container {
      padding: .5rem 0 17rem 3vw;
      margin-top: 0;
    }
  }
</style>