<template>
    <div class="dynamic_item login_container">
		<h3 class="hello_barman_text">Здравствуйте, <br>мы вас раньше <br>не видели</h3>
	    <form action="" class="hello_barman_form">
			<label class="form_label">
				<input type="text" id="enter_barman_login" placeholder="Введите логин" name="login" class="form_input">
			</label>
			<button id="enter_barman_button" @click="enterBarman($event)" class="classic_button color_button" type="submit">Войти</button>
			<p class="label_error">{{errorText}}</p>
		</form>
	</div>
</template>

<script>
import axios from 'axios'
import VueCookies from 'vue-cookies'

export default {
    name: 'Login',
	data() {
		return {
			errorText: '',
		}
	},
	props: [
		'stopper', 
		'findParentByClassName', 
		'addGAEvent',
		'showSomeBadPopup',
		'showPopup',
		'showBadPopupText'
	],
    methods: {
		enterBarman($event) {
			this.stopper($event)

			let login = this.getBarmanLogin();

			if (!login) {
				this.errorText = 'Введите логин'
				return
			}

			let button = $event.target
			button.classList.add('loading')

			this.errorText = ''

			let data = {
				login: login
			}

			let headers = {
				'x-val-use': 'Z4M2goU2cvvUQUE4ZWh70Cu6XfrwY1',
			}

			axios.post('https://platillo.ru/api/v1/seller/login', data, {
        		headers: headers
        	}).then((value) => {
          		console.log('Success answer /seller/login')
          		console.dir(value);

          		let result = value.data.data.result

          		if (result) {
            		let appX = value.data.data['app-x']

					if (appX) {
						VueCookies.set("app-x", appX, 60 * 60 * 24 * 365)
					}

					this.$router.push('/seller/home')
          		} else {
					let message = value.data.message

					if (message) {
						this.showBadPopupText(message)
					}
				}

				button.classList.remove('loading')
        	}).catch((response) => {
          		console.log('Bad answer /seller/login')
          		console.dir(response);
				button.classList.remove('loading')
				this.showSomeBadPopup()
        	})
		},
		getBarmanLogin() {
			let barmanLoginInput = document.querySelector('#enter_barman_login')
			let login = barmanLoginInput.value

			if (login) {
				return login
			}

			return false
		}
	},
	mounted() {
		let headers = {
			'x-val-use': 'Z4M2goU2cvvUQUE4ZWh70Cu6XfrwY1',
		}

		let appX = VueCookies.get('app-x')

		if (appX) {
			headers['app-x'] = appX
		}

		axios.get('https://platillo.ru/api/v1/seller/auth', {
        	headers: headers
        }).then((value) => {
          	console.log('Success answer /seller/auth')
        	console.dir(value);

          	let result = value.data.data.result

          	if (result) {
				  this.$router.push('/seller/home')
          	} else {
				VueCookies.remove('app-x')
			}
        }).catch((response) => {
          	console.log('Bad answer /seller/auth')
        	console.dir(response);
			this.showSomeBadPopup()
    	})
	}
}
</script>

<style scoped>
	#enter_barman_button {
		background-color: var(--color-1);
    	color: #FFFFFF;
	}
	#enter_barman_button.loading {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-image: none;
		color: var(--color-1);
	}
	#enter_barman_button.loading:before {
		content: '';
		display: block;
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		border: 2px dashed #FFFFFF;
		animation: loading 3s linear infinite;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}
	@keyframes loading {
		0% {
			transform: rotate(0);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>