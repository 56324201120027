<template>
  <div class="product_item product_item_with_info" :class="{not_available:!status}" :data-price="price" data-amount="0" :data-name="name" :data-value="value">
	<div class="product_item_image_container">
    <img :src="photo" alt="">
    </div>
		<h4 class="product_item_name">{{name}}</h4>
		<p class="product_item_price">{{price}}₽</p>
		<div class="product_item_amount_control">
			<a href="#" @click="changeAmount($event, false)" class="amount_control_button button_minus">-</a>
			<div class="amount_info_container">0</div>
			<a href="#" @click="changeAmount($event, true)" class="amount_control_button button_plus">+</a>
		</div>
		<a href="#" @click="showInfoProduct($event)" v-if="isDescription" class="product_item_info_button"></a>
		<div class="product_info_container">
            <p class="product_info_description">{{description}}</p>
			<h5 class="product_info_title">Состав:</h5>
			<p class="product_info_text">
				<!-- <span class="bold">Компонент 1 - </span>10 мл.<br>
				<span class="bold">Компонент 2 - </span>235 г.<br> 
				<span class="bold">Компонент 3 - </span>5 шт. <br>
				<span class="bold">Компонент 4</span> -->
                {{composition}}
			</p>
		</div>
	</div>
</template>

<script>
export default {
  name: 'Product_item',
  data() {
    return {
        value: null,
        isDescription: false,
    }
  },
  props: {
    price: Number,
    name: String,
    photo: String,
    volume: Number,
    unit: String,
    stopper: Function,
    status: Number,
    composition: String,
    description: String,
    findParentByClassName: Function,
  },
  methods: {
    changeAmount($event, isPlus) {
      this.stopper($event)

      let productItem = this.findParentByClassName('product_item', $event.target)

      if (productItem.classList.contains('not_available')) {
        return
      }

      let amountInfoContainer = productItem.querySelector('.amount_info_container')
      let amount = parseInt(amountInfoContainer.innerHTML)

      let isNewAmount = true

      if (isPlus) {
        amount++

        productItem.classList.add('not_empty')

        if (amount > this.maxAmount) {
          isNewAmount = false
          return
        }
      } else {
        amount--

        if (amount == 0) {
          productItem.classList.remove('not_empty')
        }

        if (amount < 0) {
          isNewAmount = false
          return
        }
      }

      amountInfoContainer.innerHTML = amount
      productItem.dataset.amount = amount

      if (isNewAmount) {
        this.$emit('setNewPrice', false);
      }
    },
    showInfoProduct($event) {
      this.stopper($event)

      let productItem = this.findParentByClassName('product_item', $event.target)
      productItem.classList.toggle('active_product_info')
    },
  },
  created() {
      this.value = this.volume + this.unit
      this.isDescription = this.composition || this.description
  }
}
</script>

<style scoped>
    .product_item {
        display: block;
        width: 48%;
        height: 29rem;
        background-color: #EFEFEF;
        margin-bottom: 1rem;
        padding: 1rem;
        box-sizing: border-box;
    /*	cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;*/
        position: relative;
    }
    .product_item:before {
        content: attr(data-value);
        display: block;
        width: auto;
        height: auto;
        text-align: right;
        font-size: 1.3rem;
        line-height: 1em;
        color: #383A3C;
        font-weight: 500;
        position: absolute;
        top: 20.2rem;
        right: 1rem;
        z-index: 2;
    }
    .product_item.not_available:after {
        content: 'Товар \Aзакончился';
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 1.7rem;
        line-height: 1.2em;
        font-weight: 700;
        text-transform: uppercase;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 999;
        background-color: rgba(239, 239, 239, .9);
    }
    .product_category_text_item {
        display: block;
        width: 100%;
        height: auto;
        text-align: left;
        font-size: 1.4rem;
        line-height: 1em;
        text-transform: uppercase;
        font-weight: 500;
        color: #999898;
        margin: 1rem 0;
    }
    .product_item_image_container {
        display: block;
        width: 100%;
        height: 12.5rem;
        filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.06));
    }
    .product_item_image_container img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .product_item_name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        min-height: 5rem;
        text-align: center;
        font-size: 1.6rem;
        line-height: 1.1em;
        font-weight: 700;
        margin-top: 1rem;
        text-transform: uppercase;
    }
    .product_item_price {
        display: inline-block;
        width: auto;
        height: auto;
        text-align: center;
        background-color: var(--color-2);
        color: #FFFFFF;
        font-size: 1.5rem;
        line-height: 1em;
        padding: .2em;
        font-weight: 500;
        margin-top: .3rem;
    }
    .product_item_amount_control {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: auto;
        border-top: 1px solid #DBDBDB;
        margin-top: .5rem;
        padding-top: 1rem;
    }
    .amount_control_button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 1.4em;
        height: 1.4em;
        font-size: 3rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: .2em;
    }
    .button_minus {
        background-color: #FFFFFF;
        color: #979797;
    }
    .button_plus {
        background-color: var(--color-1);
        color: #FFFFFF;
    }
    .amount_info_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 1.8rem;
        line-height: 1em;
        width: 2em;
        height: 2em;
        background-color: #FFFFFF;
        border-radius: .2em;
        box-shadow: 
            0px 2px 2px rgba(50, 50, 71, 0.06), 
            0px 2px 4px rgba(50, 50, 71, 0.06);
    }
    .product_item_info_button,
    .product_info_container {
        display: none;
    }
    .product_item_with_info .product_item_info_button {
        display: block;
        width: 3rem;
        height: 3rem;
        background-image: url(../assets/img/icon_info.png);
        background-repeat: no-repeat;
        background-size: 98% auto;
        background-position: center;
        position: absolute;
        top: .8rem;
        right: .5rem;
        z-index: 3;
        cursor: pointer;
    }
    .product_item_with_info.active_product_info .product_item_info_button {
        background-image: url(../assets/img/icon_close.png);
    }
    .product_item_with_info .product_info_container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        width: 100%;
        height: auto;
        position: absolute;
        padding-left: 1rem;
        top: 2rem;
        left: 0;
        opacity: 0;
        transform: translateY(-2rem);
        transition: transform .4s ease, opacity .4s ease;
        z-index: -999;
        box-sizing: border-box;
    }
    .product_item_with_info.active_product_info .product_info_container {
        opacity: 1;
        transform: none;
        z-index: 2;
    }
    .product_item_with_info.active_product_info .product_item_image_container,
    .product_item_with_info.active_product_info .product_item_name,
    .product_item_with_info.active_product_info .product_item_price {
        opacity: 0;
    }
    .product_info_description {
        display: block;
        width: 100%;
        height: auto;
        text-align: left;
        font-size: 1.2rem;
        line-height: 1em;
        font-weight: 400; 
    }
    .product_info_title {
        display: block;
        width: 100%;
        height: auto;
        text-align: left;
        font-size: 1.5rem;
        line-height: 1em;
        font-weight: 500;
        margin-top: 1rem;
    }
    .product_info_text {
        display: block;
        width: 100%;
        height: auto;
        text-align: left;
        font-size: 1.45rem;
        line-height: 1.3em;
        font-weight: 400;
        margin-top: .5rem;
    }
    .product_info_text .bold {
        font-weight: 600;
    }

    @media screen and (min-aspect-ratio: 4/3) {
      	.product_item {
        	display: block;
        	width: 24%;
        	height: 29rem;
        	background-color: #EFEFEF;
        	margin-bottom: 1.5rem;
			margin-right: 1.5rem;
        	padding: 1rem;
        	box-sizing: border-box;
        	position: relative;
      	}
    }
</style>