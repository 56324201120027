<template>
  	<div class="dynamic_item current_order_container">
		<div class="exit_button_container">
			<router-link to="/seller/login" @click="deleteAppCookie()" class="exit_button">Выйти</router-link>
		</div>
		<p class="order_text">Заказ № <span class="current_order_number">{{orderId}}</span>:</p>
		<div class="current_orders_scrollzone_container">
			<div class="current_orders_scrollzone">
				<div class="current_orders_box">
					<seller-order-item
						v-for="item in sellerOrders"
						:name="item.Name"
						:price="item.Price"
						:amount="item.Amount"
						:quantity="item.Quantity"
						:key="item.id">
					</seller-order-item>
				</div>
			</div>
		</div>
		<!-- <router-link to="/seller/home" @click="closeOrder()" class="classic_button color_button"><span>Закрыть заказ</span></router-link> -->
		<button id="close_order_button" @click="closeOrder($event)" class="classic_button color_button">Закрыть заказ</button>
		<p class="error_text">{{errorText}}</p>
	</div>
</template>

<script>
import axios from 'axios'
import VueCookies from 'vue-cookies'
import Seller_order_item from '@/components/Seller_order_item'

export default {
  	name: 'SellerOrder',
	data() {
		return {
			orderToken: '',
			orderId: '',
			sellerOrders: [],
			errorText: ''
		}
	},
	components: {
        'seller-order-item': Seller_order_item,
	},
  	props: {
    	id: String,
		showSomeBadPopup: Function,
		isCameraAtcive: Boolean,
		
  	},
  	methods: {
    	deleteAppCookie() {
      		VueCookies.remove('app-x')
    	},
		closeOrder($event) {
			let button = $event.target
			button.classList.add('loading')

			let headers = {
				'x-val-use': 'Z4M2goU2cvvUQUE4ZWh70Cu6XfrwY1',
			}

			let appX = VueCookies.get('app-x')

			if (appX) {
				headers['app-x'] = appX
			}

			let data = {
				'x-val': this.orderToken
				// token: this.orderToken
			}

			console.log("Close data")
			console.dir(data)

			axios.post('https://platillo.ru/api/v1/seller/order/close', data, {
				headers: headers
			}).then((value) => {
				console.log('Success answer /seller/close')
				console.dir(value);

				this.errorText = ''

				let result = value.data.data.result

				if (result) {
					this.$router.push('/seller/home')
				} else {
					let message = value.data.message

					if (message) {
						this.errorText = message
					}
				}

				button.classList.remove('loading')
			}).catch((response) => {
				console.log('Bad answer /seller/close')
				console.dir(response);
				button.classList.remove('loading')
				this.showSomeBadPopup()
			})
		}
  	},
  	mounted() {
		this.$emit('setNewCameraState', false)
		
    	this.orderToken = this.$route.params.id

		console.log('Order token: ' + this.orderToken)

		if (!this.orderToken) {
			this.$router.push('/seller/home')
			return
		}

		let path = 'https://platillo.ru/api/v1/seller/order/' + this.orderToken + '/get'

		let headers = {
			'x-val-use': 'Z4M2goU2cvvUQUE4ZWh70Cu6XfrwY1',
		}

		let appX = VueCookies.get('app-x')

		if (appX) {
			headers['app-x'] = appX
		}

      	axios.get(path, {
        	headers: headers
        }).then((value) => {
          	console.log('Success answer /seller/order/')
        	console.dir(value);

        	let data = value.data.data
			let len = data.length

			if (len) {
				this.orderId = data[0].id

				let items = data[0].items

				this.sellerOrders = JSON.parse(items)
			} else {
				this.$router.push('/seller/home')
			}
        }).catch((response) => {
      		console.log('Bad answer /seller/order/')
      		console.dir(response);
        })
  	}
}
</script>

<style scoped>
  .current_order_container:before {
    content: '';
    display: block;
    width: 100%;
    height: 30rem;
    background-color: var(--color-2);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  .current_order_container:before {
    height: 22rem;
  }
  .current_order_container {
    display: block;
    width: 100%;
    height: auto;
    margin-top: 5rem;
    padding: 0 5vw;
    box-sizing: border-box;
  }
  .current_orders_scrollzone_container {
    display: block;
    width: 100%;
    height: 53rem;
    overflow: hidden;
    margin-top: 1rem;
  }
  .current_orders_scrollzone {
    display: block;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .order_text {
    display: block;
    width: 100%;
    height: auto;
    text-align: left;
    font-size: 3rem;
    line-height: 1em;
    font-weight: 400;
    margin-top: 3rem;
    padding-right: 6vw;
    box-sizing: border-box;
    text-transform: uppercase;
  }
  .order_text .current_order_number {
    font-weight: 900;
    font-size: 1.1em;
  }
  .current_orders_box {
    display: block;
    width: 100%;
    height: auto;
    padding: 1rem 0;
  }
	.current_order_container .classic_button {
		margin-top: 1rem;
		width: 35rem;
		height: 11rem;
		position: relative;
		z-index: 2;
		font-family: 'Montserrat';
	}
	.error_text {
		display: block;
		width: 100%;
		height: auto;
		text-align: left;
		font-size: 2rem;
		line-height: 1em;
		margin-top: 0.5em;
		font-weight: 500;
		color: #DB2433;
		position: relative;
		z-index: 1;
	}
	#close_order_button {
		background-color: var(--color-1);
    	color: #FFFFFF;
	}
	#close_order_button.loading {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-image: none;
		color: var(--color-1);
	}
	#close_order_button.loading:before {
		content: '';
		display: block;
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		border: 2px dashed #FFFFFF;
		animation: loading 3s linear infinite;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}
	@keyframes loading {
		0% {
			transform: rotate(0);
		}
		100% {
			transform: rotate(360deg);
		}
	}

  /* desktop */

  @media screen and (min-aspect-ratio: 4/3) {
    .current_orders_box {
      width: 60%;
    }
  }

</style>