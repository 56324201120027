<template>
    <button @click="showOrder($event)" class="user_order_item" :data-val="val" :data-id="id">
        <p class="user_order_prefix">Заказ №</p>
		<p class="user_order_number">{{id}}</p>
    </button>
</template>

<script>

export default {
    name: 'User_order_item',
    data() {
        return {
            keyEIN: '',
        }
    },
    props: {
        id: Number,
        val: String,
    },
    methods: {
        showOrder(event) {
            // let button = event.target;
            // let val = button.dataset.val;
            // let id = button.dataset.val;
            this.$router.push('/order/' + this.val);
            // this.$router.push({ 
            //     name: 'order', 
            //     params: {
            //         id: this.val
            //     }
            // });
        }
    }
}
</script>

<style scoped>
    .user_order_item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: auto;
        background-color: #fff;
        padding: 2rem 4rem;
        box-sizing: border-box;
        margin-top: 1.5rem;
        border-radius: .3rem;
        text-decoration: none;
        color: #000000;
        border: none;
        outline: none;
    }
    .user_order_item:first-child {
        margin-top: 0;
    }
    .user_order_prefix {
        display: block;
        width: auto;
        height: auto;
        text-align: left;
        font-size: 3rem;
        line-height: 1em;
        font-weight: 600;
    }
    .user_order_number {
        display: block;
        width: auto;
        height: auto;
        text-align: left;
        font-size: 3rem;
        line-height: 1em;
        font-weight: 900;
    }
</style>