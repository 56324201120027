<template>
    <div class="dynamic_item error_404">
		<h4 class="error_title">что-то пошло не так..</h4>
		<p class="error_text">404</p>
		<router-link to="/" class="classic_button color_button"><span>Вернуться на главную</span></router-link>
	</div>
</template>

<script>

export default {
  name: 'Error_404',
}

</script>

<style scoped>
  .error_404 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 12rem 6vw 0;
    box-sizing: border-box;
  }
  .error_title {
    display: block;
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 2.3rem;
    line-height: 1.2em;
    font-weight: 900;
    text-transform: uppercase;
  }
  .error_text {
    display: block;
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 12.7rem;
    line-height: 1em;
    font-weight: 900;
    margin-top: .5rem;
  }
  .error_404 .classic_button {
    font-size: 2rem;
    height: 8rem;
    margin-top: 10rem;
  }

  /* desktop */

  @media screen and (min-aspect-ratio: 4/3) {
    .error_title {
      font-size: 3rem;
    }
    .error_text {
      font-size: 15rem;
    }
  }

  /* iPhone 4/4S */

  @media 
    only screen and (min-device-width: 320px) 
    and (max-device-width: 480px) 
    and (-webkit-device-pixel-ratio: 2) 
    and (device-aspect-ratio: 2/3) {
    .error_404 {
      padding: 6rem 6vw 0 28vw;
    }
    .error_title {
      font-size: 2.6rem;
    }
    .error_text {
      font-size: 14rem;
    }
    .error_404 .classic_button {
      font-size: 2.2rem;
      margin-top: 2rem;
    }
}

</style>