<template>
  <div class="product_category_box">
		<p class="product_category_text_item">{{name}}</p>
    <component-product-item 
      v-for="item in products"
      :price="item.price"
      :photo="item.photo"
      :name="item.name"
      :volume="item.volume"
      :unit="item.unit"
			:status="item.status"
			:description="item.description"
			:composition="item.composition"
      :stopper="stopper"
      :findParentByClassName="findParentByClassName"
      v-bind:key="item.id"
      @setNewPrice="setNewPrice">
    </component-product-item>
  </div>
</template>

<style scoped>
	.product_category_box {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		width: 100%;
		height: auto;
	}
	.product_category_text_item {
		display: block;
		width: 100%;
		height: auto;
		text-align: left;
		font-size: 1.4rem;
		line-height: 1em;
		text-transform: uppercase;
		font-weight: 500;
		color: #999898;
		margin: 1rem 0;
  	}
  	@media screen and (min-aspect-ratio: 4/3) {
      	.product_category_box {
        	justify-content: flex-start;
      	}
    }
</style>

<script>
import Product_Item from '@/components/Product_Item'

export default {
  name: 'products_Category_Box',
  data() {
    return {
    }
  },
	props: {
    name: String,
    products: Array,
    stopper: Function,
    findParentByClassName: Function,
  },
	components: {
    'component-product-item': Product_Item,    
	},
	methods: {
		setNewPrice() {
      this.$emit('setNewPrice', false);
    }
	},
	mounted() {
		
  },
	created() {
		
	}
}
</script>