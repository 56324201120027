<template>
  <div class="dynamic_item qr_bar_container">
		<div class="exit_button_container">
			<router-link to="/seller/login" @click="deleteAppCookie()" class="exit_button">Выйти</router-link>
		</div>
		<div id="qr-reader"></div>
		<h3 class="hello_barman_text">Поднесите QR-код</h3>
		<form action="" class="hello_code_form">
			<label class="form_label">
				<p class="form_label_text">Номер заказа:</p>
				<input type="text" id="barman_code_input" placeholder="Введите номер заказа" name="login" class="form_input" autocomplete="off">
				<button id="barman_code_button" @click="checkInput($event)" class="classic_button color_button" type="submit"></button>
			</label>
			<p class="label_error">{{errorText}}</p>
		</form>
	</div>
</template>

<script>
// import {Html5QrcodeScanner} from "html5-qrcode"
import {Html5Qrcode} from "html5-qrcode"
import axios from 'axios'
import VueCookies from 'vue-cookies'

export default {
  name: 'SellerHome',
  props: {
    price: Number,
    name: String,
    photo: String,
    stopper: Function,
    findParentByClassName: Function,
    showPopup: Function,
	showBadPopupText: Function,
	isCameraAtcive: Boolean,
  },
  	data() {
    	return {
      		camerasArr: [],
      		codeInput: null,
      		html5QrCode: null,
	  		errorText: '',
			isServerChating: false,
    	}
  	},
  	methods: {
		start() {
			Html5Qrcode.getCameras().then(devices => {
				this.camerasArr = devices;
				this.startQR(this.camerasArr);
			}).catch(err => {
				console.log(err)
				const config = { fps: 10, qrbox: 250 };
				this.html5QrCode.start({ facingMode: "environment" }, config, this.qrCodeSuccessCallback);
			});
		},
		startQR(devices) {
			if (devices && devices.length) {
				var cameraId = devices[devices.length - 1].id;

				this.html5QrCode.start(
					cameraId,
					{
						fps: 10,
						qrbox: 250
					},
					qrCodeMessage => {
						this.qrCodeSuccessCallback(qrCodeMessage, null, 'qr');
					},
				).catch(err => {
					console.dir(err) 
				});
			} 
		},
		onLoadSuccess(qrCodeMessage, enterType) {
			// if (qrCodeMessage.length != 6) {
			// 	this.showPopup('#base_popup', 'Данный заказ не найден!', 'bad_popup');
			// 	this.clearCodeInput();
			// 	return;
			// }

			let headers = {
				'x-val-use': 'Z4M2goU2cvvUQUE4ZWh70Cu6XfrwY1',
			}

			let appX = VueCookies.get('app-x')

			if (appX) {
				headers['app-x'] = appX
			}

			let data = {
				order_id: qrCodeMessage,
			}

			console.log('qrCode: ' + qrCodeMessage)

			axios.post('https://platillo.ru/api/v1/seller/order/search', data, {
        		headers: headers
        	}).then((value) => {
          		console.log('Success answer /seller/order/search')
          		console.dir(value);

				this.errorText = ''

          		let result = value.data.data.result

          		if (result) {
					let token = value.data.data.token
					this.$router.push('order/' + token);
          		} else {
					let message = value.data.message

					if (message) {
						this.showBadPopupText(message)
					}
				}

				this.clearCodeInput()
        	}).catch((response) => {
          		console.log('Bad answer /seller/order/search')
          		console.dir(response);
				this.clearCodeInput()
        	})
		},
		clearCodeInput() {
			let input = document.querySelector('#barman_code_input')
			let button = document.querySelector('#barman_code_button')

			input.value = '';
			input.classList.remove('code_verifying');
			button.classList.remove('loading')
			input.removeAttribute('readonly');
			this.setServerChatingState(false)
		},
		setServerChatingState(state) {
			this.isServerChating = state
		},
		setCameraState(state) {
			this.$emit('setNewCameraState', state);
		},
		setNewServerChating() {
			let basePopup = document.querySelector('#base_popup');
			let basePopupButton = basePopup.querySelector('.classic_button');

			if (basePopupButton) {
				basePopupButton.addEventListener('click', () => {
					this.setServerChatingState(false)
				});
			}
		},
		qrCodeSuccessCallback(decodedText, decodedResult, enterType) {
			if (this.isServerChating) {
				return
			}

			if (this.isCameraAtcive) {
				return
			}

			if (enterType == 'qr') {
				this.setCameraState(true)
			}

			console.log('get code: ' + decodedText)

			this.setServerChatingState(true)
			this.onLoadSuccess(decodedText, enterType);
		},
		startMounted() {
			this.setNewServerChating();

			this.html5QrCode = new Html5Qrcode("qr-reader");
			this.start();
		},
		deleteAppCookie() {
			VueCookies.remove('app-x')
		},
		checkInput($event) {
			this.stopper($event)

			let button = $event.target

			let input = document.querySelector('#barman_code_input')
			this.codeInput = input.value

			if (!this.codeInput) {
				return
			}
			
			// if (this.codeInput.length == 6) {
				input.classList.add('code_verifying');
				button.classList.add('loading')
				input.setAttribute('readonly', '');

				this.qrCodeSuccessCallback(this.codeInput, null, 'input');

				setTimeout(function() {
					input.blur();
				}, 50);
			// }
		}
  	},
  	mounted() {
		let headers = {
			'x-val-use': 'Z4M2goU2cvvUQUE4ZWh70Cu6XfrwY1',
		}

		let appX = VueCookies.get('app-x')

		if (appX) {
			headers['app-x'] = appX
		}

		axios.get('https://platillo.ru/api/v1/seller/auth', {
			headers: headers
		}).then((value) => {
			console.log('Success answer /seller/auth')
			console.dir(value);

			let result = value.data.data.result

			if (result) {
				this.startMounted()
			} else {
				VueCookies.remove('app-x')
				this.$router.push('login')
			}
		}).catch((response) => {
			console.log('Bad answer /seller/auth')
			console.dir(response)
			this.$router.push('login')
		})
  	}
}
</script>

<style>
  .qr_bar_container:before {
    content: '';
    display: block;
    width: 100%;
    height: 30rem;
    background-color: var(--color-2);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  .qr_bar_container:before {
    height: 22rem;
  }
  .qr_bar_container {
    display: block;
    width: 100%;
    height: auto;
    padding: 0 6vw;
    box-sizing: border-box;
    padding-top: 2rem;
  }
  #qr-reader {
    width: 80vw;
    height: 80vw;
    min-width: 250px;
    min-height: 250px;
    position: relative;
    margin: 8rem auto 2rem;
    overflow: hidden;
  }
  #qr-reader:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(../assets/img/qr_borders.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  #qr-shaded-region > div {
    opacity: 0;
  }
  #qr-shaded-region {
    border: none;
  }
  #qr-reader video,
  video {
    min-width: 400px;
    height: 100%;
    object-fit: cover;
  }
  .qr_bar_container .hello_barman_text {
    margin-top: 3rem;
    font-weight: 700;
    font-size: 3rem;
    text-align: center;
  }
  .hello_code_form {
    display: block;
    width: 100%;
    height: auto;
    position: fixed;
    bottom: 3.5rem;
    left: 0;
    z-index: 2;
    padding-right: 6vw;
    padding-left: 19vw;
    box-sizing: border-box;
  }
	.hello_code_form .form_label {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
		height: auto;
	}
	.hello_code_form .form_label_text {
		margin-bottom: 2rem;
	}
  .form_label_text {
    display: block;
    width: 100%;
    height: auto;
    text-align: left;
    font-size: 4rem;
    line-height: 1em;
    font-weight: 900;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  .qr_bar_container .form_input {
    margin-top: 2rem;
    background-color: #EFEFEF;
  }
  .qr_bar_container .form_input::placeholder {
      color: #787878;
  }
  .hidden_button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -999;
    width: .1px;
    height: .1px;
    opacity: 0;
    overflow: hidden;
  }
	#barman_code_input {
		font-size: 2.2rem;
		transition: opacity .4s ease;
		width: 75%;
		padding: 1em;
		margin-top: 0;
	}
	#barman_code_input.code_verifying {
		opacity: .5;
	}
	#barman_code_button {
	  	display: block;
		width: 20%;
		height: 7rem;
		background-image: url(../assets/img/icon_search.png);
		background-repeat: no-repeat;
		background-size: auto 50%;
		background-position: center;
		background-color: var(--color-1);
		border-radius: 0.5rem;
  	}
	#barman_code_button.loading {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-image: none;
	}
	#barman_code_button.loading:before {
		content: '';
		display: block;
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		border: 2px dashed #FFFFFF;
		animation: loading 3s linear infinite;
	}
	@keyframes loading {
		0% {
			transform: rotate(0);
		}
		100% {
			transform: rotate(360deg);
		}
	}

  /* desktop */

  @media screen and (min-aspect-ratio: 4/3) {
    #qr-reader {
      width: 400px;
      height: 400px;
    }
  }

  /* iPad */

  @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1) {
      #qr-reader {
        width: 400px;
        height: 400px;
      }
  }

</style>