<template>
    <div class="current_order_item">
		<p class="current_order_name">{{name}}</p>
        <p class="current_order_data">
            <span class="current_order_data_text">{{quantity}}</span>
            <span class="current_order_amount_title">&nbsp;шт.</span>
        </p>
		<!-- <p class="current_order_data">
            <span class="current_order_data_text">{{quantity}}</span>
            <span class="current_order_amount_title">&nbsp;шт.</span>
            <span class="current_order_amount_title">&nbsp;по&nbsp;</span>
            <span class="current_order_data_text">{{price}}</span>
            <span class="current_order_amount_title">&nbsp;₽</span>
        </p>
        <p class="current_order_data">
            <span class="current_order_amount_title">На сумму&nbsp;</span>
            <span class="current_order_data_text">{{amount}}</span>
            <span class="current_order_amount_title">&nbsp;₽</span>
        </p> -->
	</div>
</template>

<script>

export default {
    name: 'User_order_item',
    props: ['name', 'price', 'amount', 'quantity'],
}
</script>

<style scoped>
    .current_order_item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: auto;
        background-color: #FAFAFA;
        padding: 2rem 3vw;
        box-sizing: border-box;
        border-radius: .5rem;
    }
    .current_order_item:not(:nth-child(1)) {
        margin-top: 1rem;
    }
    .current_order_name {
        display: block;
        width: auto;
        height: auto;
        text-align: left;
        font-size: 2.2rem;
        line-height: 1em;
        font-weight: 700;
        text-transform: uppercase;
        padding-right: 1vw;
        box-sizing: border-box;
    }
    .current_order_data {
        display: block;
        width: auto;
        height: auto;
        font-size: 2.5rem;
        line-height: 1.1em;
        font-weight: 700;
        color: var(--color-1);
        text-align: left;
    }
    .current_order_data_text {
        font-size: 1em;
        line-height: 1.1em;
    }
    .current_order_amount_title {
        font-size: .85em;
        color: #000000;
    }
</style>