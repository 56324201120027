<template>
    <component-product-category 
      v-for="elem in productsArr"
      :name="elem.name"
      :products="elem.items"
      :stopper="stopper"
      :findParentByClassName="findParentByClassName"
      v-bind:key="elem.id"
      @setNewPrice="setNewPrice">
    </component-product-category>
</template>

<style scoped>
	.products_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    padding-bottom: 1rem;
    padding-right: 1.5vw;
    box-sizing: border-box;
  }
</style>

<script>
import products_Category_Box from '@/components/products_Category_Box'

export default {
  name: 'Product_Creator',
  data() {
    return {
      sortedArr: [],
      productsArr: [],
    }
	},
  props: {
    productsArr: Array,
		findParentByClassName: Function,
		stopper: Function,
		setNewAmount: Function,
  },
  components: {
    'component-product-category': products_Category_Box,    
	},
  methods: {
		setNewPrice() {
      this.$emit('setNewPrice', false);
    }
  },
	watch: {
	}
}
</script>